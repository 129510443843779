import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import { Actions, reportBi } from './utils/editor-script-bi-logger';
import { Translations } from './utils/translations';
import {
  BOOK_BUTTON_WIDGET_CONTROLLER_ID,
  DAILY_TIMETABLE_WIDGET_CONTROLLER_ID,
  experiments,
  HandleActionPayload,
  PageId,
  STAFF_LIST_WIDGET_CONTROLLER_ID,
  WidgetsId,
} from './constants';
import {
  addBookingsPagesAsPanel,
  createBookCheckoutState,
  createBookingCalendarState,
  createBookingFormState,
  createServicePageState,
  handleOpenBookingsPagesPanel,
  removeBookCheckoutPageOnEcom,
  updatePagesTranslations,
} from './utils/pages-panel-actions';
import {
  addBookingsCalendarRoute,
  addServicePageRoute,
} from './utils/pages-routes';
import { shouldProposeMigration } from './utils/migrate-actions';
import { migrateAction } from './migration';
import { getCurrentVersion } from './utils/ci-actions';
import { migrator, proposeListMigration } from './utils/migration-modals';
import {
  createFedopsLogger,
  getAllBookingsPages,
  getPageData,
  getStateBoxByBookingsAppBuilderWidget,
  isADI,
  isBookingCalendarInstalled,
  isBookingFormInstalled,
  isBookingsCheckoutInstalled,
  isEditorX,
  isServicePageInstalled,
  onRemoveApp,
  removePage,
} from './utils/editor-sdk-actions';
import {
  BookingsAppBuilderStaticsUrls,
  DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID,
  STAFF_LIST_WIDGET_DEV_CENTER_ID,
} from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';
import { DailyTimetableEditorComponentModel } from './editor-components/daily-timetable';
import { BookButtonEditorComponentModel } from './editor-components/book-button';
import { StaffListEditorComponentModel } from './editor-components/staff-list';
import { withEcomPlatform } from '@wix/ecom-platform-sdk';
import { isExperimentEnabled } from './utils/experiments';
import {
  createAServiceEvent,
  generateActions,
  manageBookingsEvent,
  manageStaffEvent,
  manageStateEvent,
  openBookButtonSettingsEvent,
  openBookingsAddPanel,
  openBookingsPagesPanel,
  openDailyTimeTableSettingsEvent,
  openDailyTimeTableSettingsTextTabEvent,
  openStaffListSettingsEvent,
  openStaffListTextSettingsEvent,
} from './utils/editor-actions';
import { EditorSdkAdapter } from '@wix/bookings-adapter-editor-sdk';
import {
  EditorReadyOptions,
  EditorSDK,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';
import {
  MA_APP_IDS,
  maybeInstallMembersArea,
  withMembersArea,
} from '@wix/members-area-integration-kit';
import createAppDescriptor from './manifest/app-descriptor/app-descriptor';
import {
  openBookingsDashboardAddNewService,
  openManageBookings,
} from './utils/backoffice-actions';
import { handleOpenBookingsAddPanel } from './utils/app-descriptor-actions';
import {
  addHistoryEventsHandlers,
  onHistoryEvent,
} from './utils/history-events';
import { EditorScriptApi } from './api/api';
import {
  BOOKINGS_APP_DEF_ID,
  ECOM_APP_DEF_ID,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { addBookingCheckoutPage } from './utils/pages-actions';
import {
  installCart,
  markAsEcom,
  requiredEcomComponentsAction,
  skipEcomInstallationAction,
  validateAndFixEcomSite,
} from './utils/ecom-migration/ecom-actions';
import { WidgetPluginInterfaces } from '@wix/widget-plugins-ooi/interfaces';

const checkIfUserIsPremium = (bookingsData) => {
  return bookingsData?.vendorProductId ?? false;
};

let isEcomReady = false;
let skipEcomInstallation = async () => false;
let requiredEcomComponents = async () => [];

export const createEditorScript = (isBookingsOnEcom = false) => {
  try {
    let instance,
      appToken,
      sdk,
      locale,
      isAdi,
      isProposeMigrationNeeded,
      allSitePages,
      isFirstInstall,
      msid,
      editorOrigin,
      isBookingCalendarPageInstalled,
      editorScriptApi: EditorScriptApi,
      editorOptions: EditorReadyOptions,
      fedopsLogger: IFedOpsLogger;
    const deleteBookingsEvent = 'deleteBookings';
    const deletePageEvent = 'deletePage';
    const pageChangedEvent = 'focusedPageChanged';
    const widgetGfppClicked = 'widgetGfppClicked';
    const componentGfppClicked = 'componentGfppClicked';
    const componentStyleChanged = 'componentStyleChanged';
    const gfppOriginDoubleClick = 'double_click';
    const appActionClicked = 'appActionClicked';

    const editorTranslation = new Translations();
    let bookingsEditorComponentModels;
    let actions;
    let appDescriptor;
    let ooiControllersStageData;
    let isOOITemplateMigrationEnabled;
    let isMigrateFromGetAncestorsEnabled;
    let isRemoveDeleteActionOnMainPageEnabled;

    return {
      editorReady: async (
        _editorSDK: EditorSDK,
        _appToken: string,
        options: EditorReadyOptions,
      ) => {
        try {
          fedopsLogger = createFedopsLogger(options);
          fedopsLogger.interactionStarted(Actions.EDITOR_READY);

          if (options?.firstInstall) {
            fedopsLogger.interactionStarted(Actions.BOOKINGS_INSTALLATION);
          }

          msid = await _editorSDK.document.info.getMetaSiteId('');
          editorOrigin = options?.origin?.type;
          isFirstInstall = options?.firstInstall;

          await addHistoryEventsHandlers(_editorSDK);

          editorOptions = options;
          isBookingCalendarPageInstalled = await isBookingCalendarInstalled(
            _editorSDK,
          );
          const editorSdkAdapter = new EditorSdkAdapter(_editorSDK, _appToken);
          editorScriptApi = new EditorScriptApi(
            options.essentials.httpClient as any,
          );
          locale = await editorSdkAdapter.getEditorLanguage();
          const currentVersion = await getCurrentVersion(editorSdkAdapter);
          const bookingsData = await editorSdkAdapter.getBookingsData();
          instance = bookingsData.instance;
          await editorTranslation.init(locale, currentVersion);
          actions = generateActions(editorTranslation);

          isOOITemplateMigrationEnabled = await isExperimentEnabled(
            experiments.OOI_TEMPLATE_MIGRATION_ENABLED,
          );
          isMigrateFromGetAncestorsEnabled = await isExperimentEnabled(
            experiments.BOOKINGS_MIGRATE_FROM_GET_ANCESTORS,
          );

          isRemoveDeleteActionOnMainPageEnabled = await isExperimentEnabled(
            experiments.BOOKINGS_IS_REMOVE_DELETE_ACTION_ON_MAIN_PAGE,
          );

          const isPremium = checkIfUserIsPremium(bookingsData);
          appDescriptor = createAppDescriptor(
            actions,
            isPremium,
            editorTranslation,
          );
          const isSkipMemberInstallationEnabled = await isExperimentEnabled(
            experiments.SKIP_MEMBER_INSTALLATION,
          );
          const isPluginInstallationOnServicePageEnabled =
            await isExperimentEnabled(
              experiments.PLUGIN_INSTALLATION_ON_SERVICE_PAGE_ENABLED,
            );
          ooiControllersStageData = {
            [WidgetsId.CALENDAR_PAGE]: {
              default: {
                layoutLimits: {
                  desktop: {
                    minWidth: 280,
                  },
                  mobile: {
                    minWidth: 280,
                  },
                },
              },
            },
            [WidgetsId.CALENDAR_WIDGET]: {
              default: {
                layoutLimits: {
                  desktop: {
                    minWidth: 280,
                  },
                  mobile: {
                    minWidth: 280,
                  },
                },
              },
            },
            [WidgetsId.WEEKLY_TIMETABLE]: {
              default: {
                layoutLimits: {
                  desktop: {
                    minWidth: 640,
                  },
                  mobile: {
                    minWidth: 280,
                  },
                },
              },
            },
            [WidgetsId.DAILY_AGENDA]: {
              default: {
                layoutLimits: {
                  desktop: {
                    minWidth: 280,
                  },
                  mobile: {
                    minWidth: 280,
                  },
                },
              },
            },
            [WidgetsId.BOOKINGS_LIST_PAGE]: {
              default: {
                layoutLimits: {
                  desktop: {
                    minWidth: 600,
                  },
                  mobile: {
                    minWidth: 260,
                  },
                },
              },
            },
            [WidgetsId.BOOKINGS_LIST_WIDGET]: {
              default: {
                layoutLimits: {
                  desktop: {
                    minWidth: 600,
                  },
                  mobile: {
                    minWidth: 260,
                  },
                },
              },
            },
            ...(isPluginInstallationOnServicePageEnabled
              ? {
                  [WidgetsId.SERVICE_PAGE]: {
                    default: {
                      slots: {
                        slot1: {
                          displayName: 'slot1',
                          interfaces: [WidgetPluginInterfaces.BOOKINGS_SERVICE],
                        },
                      },
                    },
                  },
                }
              : {}),
          };
          bookingsEditorComponentModels = {
            [DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID]:
              new DailyTimetableEditorComponentModel(
                editorSdkAdapter,
                editorTranslation,
                actions.manageBookingsAction,
                actions.manageStateAction,
                actions.openDailyTimeTableSettings,
                actions.openDailyTimeTableSettingsTextTab,
              ),
            [BOOK_BUTTON_WIDGET_CONTROLLER_ID]:
              new BookButtonEditorComponentModel(
                editorSdkAdapter,
                editorTranslation,
                actions.manageBookingsAction,
                actions.bookButtonSettings,
              ),
            [STAFF_LIST_WIDGET_DEV_CENTER_ID]:
              new StaffListEditorComponentModel(
                editorSdkAdapter,
                editorTranslation,
                {
                  manageStaffAction: actions.manageStaffAction,
                  staffListSettingsAction: actions.openStaffListSettings,
                  openStaffListTextSettingsAction:
                    actions.openStaffListTextSettings,
                },
              ),
          };
          await editorSdkAdapter.registerToCustomEvents([
            componentStyleChanged,
          ]);
          const [
            isBookingsCalendarAppReflowEnable,
            isBookingsServicePageAppReflowEnable,
          ] = await Promise.all([
            isExperimentEnabled(experiments.BOOKINGS_CALENDAR_APP_REFLOW),
            isExperimentEnabled(experiments.BOOKINGS_SERVICE_PAGE_APP_REFLOW),
          ]);

          fedopsLogger.interactionEnded(Actions.EDITOR_READY);
          return new Promise(async (resolve) => {
            try {
              fedopsLogger.interactionStarted(Actions.EDITOR_READY_CALLBACK);
              appToken = _appToken;
              sdk = _editorSDK;
              isAdi = isADI(options);

              if (isFirstInstall) {
                const setupBookingsBoilerplate = async () => {
                  await addBookingsPagesAsPanel(sdk, appToken);
                  const isIntroFunnelInstallation =
                    options.origin?.info?.type ===
                    InstallationOriginType.INTRO_FUNNEL;
                  const isSilentInstall =
                    isSkipMemberInstallationEnabled &&
                    (options.origin?.info?.type ===
                      InstallationOriginType.SILENT_INSTALL_SITE_CREATION ||
                      options.origin?.info?.type ===
                        InstallationOriginType.SILENT_INSTALL);
                  if (
                    !isAdi &&
                    !isIntroFunnelInstallation &&
                    !isSilentInstall
                  ) {
                    await maybeInstallMembersArea({ biData: options.biData });
                  }
                };
                try {
                  await sdk.document.transactions.runAndWaitForApproval(
                    appToken,
                    async () => {
                      await setupBookingsBoilerplate();
                    },
                  );
                } catch (error) {
                  console.error('Concurrent editing problem: ', error);
                }
              } else {
                await sdk.document.application.registerToCustomEvents(
                  appToken,
                  {
                    eventTypes: [pageChangedEvent],
                  },
                );
                isProposeMigrationNeeded = shouldProposeMigration(
                  sdk,
                  isAdi,
                  appToken,
                  instance,
                );
              }

              if (
                !isBookingsOnEcom ||
                !(await editorScriptApi.canInstallEcom())
              ) {
                await addBookingCheckoutPage(sdk, appToken);
              }

              allSitePages = await sdk.pages.data.getAll();
              await createBookCheckoutState(sdk, appToken, allSitePages);
              await createServicePageState(sdk, appToken, allSitePages);
              await createBookingCalendarState(sdk, appToken, allSitePages);
              await createBookingFormState(sdk, appToken, allSitePages);
              await removeBookCheckoutPageOnEcom(
                sdk,
                appToken,
                editorScriptApi,
              );

              const isBookingTranslatePagesEnabled = await isExperimentEnabled(
                experiments.BOOKINGS_TRANSLATE_PAGES,
              );

              if (isBookingTranslatePagesEnabled) {
                await updatePagesTranslations(
                  sdk,
                  appToken,
                  allSitePages,
                  editorTranslation,
                );
              }

              if (isBookingsCalendarAppReflowEnable) {
                await addBookingsCalendarRoute(sdk, appToken, allSitePages);
              }
              if (isBookingsServicePageAppReflowEnable) {
                await addServicePageRoute(sdk, appToken, allSitePages);
              }

              // Initial ECom Business Logic
              requiredEcomComponents = requiredEcomComponentsAction();
              skipEcomInstallation = skipEcomInstallationAction({
                isFirstInstall,
                msid,
                editorOrigin,
                _editorSDK,
                editorScriptApi,
                appToken: _appToken,
                locale,
                editorTranslation,
                fedopsLogger,
                instance,
                migrationActionInput: {
                  editorSdk: _editorSDK,
                  appToken: _appToken,
                  editorScriptApi,
                  editorOptions,
                  instance,
                  payload: {
                    appDefinitionId: BOOKINGS_APP_DEF_ID,
                    OOIMigration: true,
                    OOICheckCanMigrate: true,
                  },
                  isBookingsOnEcom,
                  displayStepsBar: {
                    editorTranslation,
                  },
                },
              });

              await validateAndFixEcomSite({
                isFirstInstall,
                _editorSDK,
                msid,
                editorOrigin,
                editorScriptApi,
                appToken: _appToken,
                locale,
                editorTranslation,
                fedopsLogger,
                instance,
                migrationActionInput: {
                  editorSdk: _editorSDK,
                  appToken: _appToken,
                  editorScriptApi,
                  editorOptions,
                  instance,
                  payload: {
                    appDefinitionId: BOOKINGS_APP_DEF_ID,
                    OOIMigration: true,
                    OOICheckCanMigrate: true,
                  },
                  isBookingsOnEcom,
                  displayStepsBar: {
                    editorTranslation,
                  },
                },
              });
              await installCart({
                editorSdk: _editorSDK,
                editorOptions,
                locale,
                instance,
                msid,
                editorOrigin,
                editorScriptApi,
                fedopsLogger,
              });

              fedopsLogger.interactionEnded(Actions.EDITOR_READY_CALLBACK);
              resolve(undefined);
            } catch (e) {
              const errorMessage = `${
                e?.message ? e.message : JSON.stringify(e)
              } - Action ${Actions.EDITOR_READY_CALLBACK}`;
              reportBi({
                msid,
                origin: editorOrigin,
                isFirstInstall,
                actionType: Actions.EDITOR_READY_CALLBACK,
                actionSucceed: false,
                errorMessage,
              });
              throw new Error(errorMessage);
            }
          });
        } catch (e) {
          const errorMessage = `${
            e?.message ? e.message : JSON.stringify(e)
          } - Action ${Actions.EDITOR_READY}`;
          reportBi({
            msid,
            origin: editorOrigin,
            isFirstInstall,
            actionType: Actions.EDITOR_READY,
            actionSucceed: false,
            errorMessage,
          });
          throw new Error(errorMessage);
        }
      },
      getAppManifest: async (
        _editorSDK: EditorSDK,
        _appToken: string,
        options: EditorReadyOptions,
      ) => {
        try {
          fedopsLogger.interactionStarted(Actions.GET_APP_MANIFEST);
          const [
            isBookingsCalendarAppReflowEnable,
            isBookingsServicePageAppReflowEnable,
            isBookingsHideServicePageSubPagesEnable,
          ] = await Promise.all([
            isExperimentEnabled(experiments.BOOKINGS_CALENDAR_APP_REFLOW),
            isExperimentEnabled(experiments.BOOKINGS_SERVICE_PAGE_APP_REFLOW),
            isExperimentEnabled(
              experiments.BOOKINGS_HIDE_SERVICE_PAGE_SUB_PAGES,
            ),
          ]);
          isAdi = isADI(options);
          const isEditor_x: boolean = isEditorX(options);
          const result = {
            ...(appDescriptor ? { appDescriptor } : {}),
            controllersStageData: {
              [DAILY_TIMETABLE_WIDGET_CONTROLLER_ID]: {
                default:
                  bookingsEditorComponentModels[
                    DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID
                  ].connection,
              },
              [BOOK_BUTTON_WIDGET_CONTROLLER_ID]: {
                default:
                  bookingsEditorComponentModels[
                    BOOK_BUTTON_WIDGET_CONTROLLER_ID
                  ].connection,
              },
              [STAFF_LIST_WIDGET_CONTROLLER_ID]: {
                default:
                  bookingsEditorComponentModels[STAFF_LIST_WIDGET_DEV_CENTER_ID]
                    .connection,
              },
              ...ooiControllersStageData,
            },
            pages: {
              pageActions: {
                default: [
                  'Pages_Actions_Page_Rename',
                  ...(isRemoveDeleteActionOnMainPageEnabled
                    ? []
                    : [
                        {
                          title: editorTranslation.t(
                            'bookings-pages.page.delete',
                          ),
                          event: deletePageEvent,
                          icon: 'deleteAction',
                          type: 'page_remove',
                        },
                      ]),
                ],
                bookCheckoutPage: [],
                servicePage: [],
                bookingCalendar: [],
                bookingForm: [],
              },
              pageSettings: {
                default: [
                  {
                    title: editorTranslation.t('bookings-pages.tabs.page-info'),
                    helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                    type: 'page_info',
                  },
                  {
                    title: editorTranslation.t('bookings-pages.tabs.layout'),
                    type: 'layout',
                  },
                  {
                    title: editorTranslation.t(
                      'bookings-pages.tabs.permissions',
                    ),
                    type: 'permissions',
                  },
                  {
                    title: editorTranslation.t('bookings-pages.tabs.seo'),
                    type: 'seo',
                  },
                ],
                bookCheckoutPage: [
                  {
                    title: editorTranslation.t('bookings-pages.tabs.page-info'),
                    url: `https://bookings.wixapps.net/bookings-widget/book-checkout-page-info?locale=${locale}&instance=${instance}&isBookingCalendarInstalled=${isBookingCalendarPageInstalled}`,
                    helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
                    type: 'page_info',
                  },
                  {
                    title: editorTranslation.t('bookings-pages.tabs.layout'),
                    type: 'layout',
                  },
                  {
                    title: editorTranslation.t(
                      'bookings-pages.tabs.permissions',
                    ),
                    type: 'permissions',
                  },
                ],
                servicePage: [
                  {
                    title: editorTranslation.t('bookings-pages.tabs.page-info'),
                    url: `https://bookings.wixapps.net/bookings-widget/service-page-info?locale=${locale}&instance=${instance}`,
                    helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                    type: 'page_info',
                  },
                  {
                    title: editorTranslation.t('bookings-pages.tabs.layout'),
                    type: 'layout',
                  },
                  {
                    title: editorTranslation.t(
                      'bookings-pages.tabs.permissions',
                    ),
                    type: 'permissions',
                  },
                ],
                bookingCalendar: [
                  {
                    title: editorTranslation.t('bookings-pages.tabs.page-info'),
                    url: `https://bookings.wixapps.net/bookings-widget/booking-calendar-page-info?locale=${locale}&instance=${instance}`,
                    helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                    type: 'page_info',
                  },
                  {
                    title: editorTranslation.t('bookings-pages.tabs.layout'),
                    type: 'layout',
                  },
                  {
                    title: editorTranslation.t(
                      'bookings-pages.tabs.permissions',
                    ),
                    type: 'permissions',
                  },
                ],
                bookingForm: [
                  {
                    title: editorTranslation.t('bookings-pages.tabs.page-info'),
                    url: `https://bookings.wixapps.net/bookings-widget/booking-form-page-info?locale=${locale}&instance=${instance}`,
                    helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                    type: 'page_info',
                  },
                  {
                    title: editorTranslation.t('bookings-pages.tabs.layout'),
                    type: 'layout',
                  },
                  {
                    title: editorTranslation.t(
                      'bookings-pages.tabs.permissions',
                    ),
                    type: 'permissions',
                  },
                ],
              },
              applicationSettings: {
                default: {
                  displayName: editorTranslation.t('bookings-pages.title'),
                  helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                  pageReplace: {
                    [PageId.BOOKINGS_CALENDAR_PAGE]: {
                      replaceable:
                        isBookingsCalendarAppReflowEnable &&
                        !isEditor_x &&
                        !isAdi,
                    },
                    [PageId.BOOKINGS_SERVICE_PAGE]: {
                      replaceable:
                        isBookingsServicePageAppReflowEnable &&
                        !isEditor_x &&
                        !isAdi,
                    },
                  },
                },
              },
              applicationActions: {
                default: {
                  defaultValues: [
                    {
                      title: editorTranslation.t(
                        'bookings-pages.actions.manage',
                      ),
                      event: manageBookingsEvent,
                      icon: 'settingsAction',
                    },
                    {
                      title: editorTranslation.t(
                        'bookings-pages.actions.delete',
                      ),
                      event: deleteBookingsEvent,
                      icon: 'deleteRadio',
                    },
                  ],
                },
              },
              pageDescriptors: {
                default: {
                  icon: 'bookingPageType',
                  orderIndex: 5,
                },
                servicePage: {
                  icon: 'bookingPageType',
                  orderIndex: 4,
                },
                bookingCalendar: {
                  icon: 'bookingPageType',
                  orderIndex: 3,
                },
                bookingForm: {
                  icon: 'bookingPageType',
                  orderIndex: 2,
                },
                bookCheckoutPage: {
                  icon: 'bookingPageType',
                  orderIndex: 1,
                },
              },
              subPagesToHide: {
                dynamicPagesNavBar: isBookingsHideServicePageSubPagesEnable
                  ? ['wix.bookings.sub_pages.service_page']
                  : [],
                linkPanel: isBookingsHideServicePageSubPagesEnable
                  ? ['wix.bookings.sub_pages.service_page']
                  : [],
              },
            },
          };
          fedopsLogger.interactionEnded(Actions.GET_APP_MANIFEST);
          return result;
        } catch (e) {
          const errorMessage = `${
            e?.message ? e.message : JSON.stringify(e)
          } - Action ${Actions.GET_APP_MANIFEST}`;
          reportBi({
            msid,
            origin: editorOrigin,
            isFirstInstall,
            actionType: Actions.GET_APP_MANIFEST,
            actionSucceed: false,
            errorMessage,
          });
          throw new Error(errorMessage);
        }
      },
      onEvent: async (event, editorSDK) => {
        fedopsLogger.interactionStarted(Actions.ON_EVENT);

        const { eventType, eventPayload } = event;
        const editorSdkAdapter = new EditorSdkAdapter(editorSDK, appToken);
        onHistoryEvent(event);

        async function openManageStaff() {
          await editorSdkAdapter.openStaffList('editor');
          editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE');
        }

        function focusOnStateBox(widgetComponentRef) {
          getStateBoxByBookingsAppBuilderWidget(
            editorSDK,
            appToken,
            widgetComponentRef,
          ).then((stateBoxComponentRef) => {
            editorSdkAdapter.selectComponent(stateBoxComponentRef);
          });
        }

        async function openDailyTimeTableSettingsPanel(
          componentRef,
          tab?: 'text',
        ) {
          const innerPath = tab ? `/${tab}` : ``;

          const url = `https://bookings.wixapps.net/bookings-app-builder-statics/daily-timetable/settings${innerPath}`;
          const title = editorTranslation.t(
            'bookings.daily-timetable.settings.label.Header',
          );
          const helpId = 'b78664ce-6970-4eb8-9ee6-6ce1d79d9b61';
          const options = {
            width: 406,
          };
          await editorSdkAdapter.openSettingsPanel(
            title,
            url,
            componentRef,
            helpId,
            instance,
            options,
          );
        }

        async function openStaffListSettingsPanel(componentRef, tab = '') {
          const url = `https://bookings.wixapps.net/bookings-app-builder-statics/staff-widget/settings/${tab}`;
          const title = editorTranslation.t(
            'bookings.staff-list.settings.label.Header',
          );
          const helpId = '8a5134e7-7689-4772-acc4-fb2a9830b54e';
          const options = {
            width: 406,
          };
          await editorSdkAdapter.openSettingsPanel(
            title,
            url,
            componentRef,
            helpId,
            instance,
            options,
          );
        }

        async function openBookButtonSettingsPanel(componentRef) {
          const url = `${BookingsAppBuilderStaticsUrls.BOOK_BUTTON_SETTINGS}`;
          const title = editorTranslation.t(
            'bookings.book-button.settings.label.Header',
          );
          const helpId = '0b25d4f6-6381-4d40-ac59-97a7d14cc7c2';
          await editorSdkAdapter.openSettingsPanel(
            title,
            url,
            componentRef,
            helpId,
            instance,
          );
        }

        async function handleComponentStyleChange() {
          const styledComponentRef = eventPayload.compRef;
          const linkedBookingsWidget = await getParentWidget(
            styledComponentRef,
          );
          if (linkedBookingsWidget) {
            const { widgetComponentRef, editorComponentModel } =
              linkedBookingsWidget;
            await editorComponentModel.handleStylesChange(
              widgetComponentRef,
              styledComponentRef,
            );
          }
        }

        async function getParentWidget(componentRef) {
          if (isMigrateFromGetAncestorsEnabled) {
            const controller =
              await editorSDK.components.refComponents.getHostComponentRef(
                appToken,
                {
                  componentRef,
                },
              );
            const controllerId = await editorSdkAdapter.getWidgetId(controller);

            if (bookingsEditorComponentModels[controllerId]) {
              return {
                widgetComponentRef: controller,
                editorComponentModel:
                  bookingsEditorComponentModels[controllerId],
              };
            }
            return null;
          } else {
            const ancestors = await editorSDK.components.getAncestors(
              appToken,
              {
                componentRef,
              },
            );
            for (const ancestor of ancestors) {
              const ancestorId = await editorSdkAdapter.getWidgetId(ancestor);
              if (bookingsEditorComponentModels[ancestorId]) {
                return {
                  widgetComponentRef: ancestor,
                  editorComponentModel:
                    bookingsEditorComponentModels[ancestorId],
                };
              }
            }
            return null;
          }
        }

        async function getControllerWidget(childComponentRef) {
          if (isMigrateFromGetAncestorsEnabled) {
            const hostWidget =
              await editorSDK.components.refComponents.getHostComponentRef(
                appToken,
                {
                  componentRef: childComponentRef,
                },
              );
            const ancestors = await editorSDK.components.getAncestors(
              appToken,
              {
                componentRef: childComponentRef,
              },
            );
            const widgets = ancestors.find(({ id }) => id === hostWidget.id)
              ? ancestors
              : [...ancestors, hostWidget];
            for (const widget of widgets) {
              const data = await editorSDK.components.data.get(appToken, {
                componentRef: widget,
              });
              if (data?.type === 'AppController') {
                return widget;
              }
            }
            return null;
          } else {
            const ancestors = await editorSDK.components.getAncestors(
              appToken,
              {
                componentRef: childComponentRef,
              },
            );
            for (const ancestor of ancestors) {
              const data = await editorSDK.components.data.get(appToken, {
                componentRef: ancestor,
              });
              if (data?.type === 'AppController') {
                return ancestor;
              }
            }
            return null;
          }
        }

        async function handleDoubleClick() {
          let parentWidget;
          try {
            parentWidget = await getParentWidget(eventPayload.componentRef);
          } catch (e) {
            // todo try for book-button
          }
          if (
            parentWidget?.editorComponentModel.baseComponentModel
              .doubleClickAction
          ) {
            eventPayload.id =
              parentWidget.editorComponentModel.baseComponentModel.doubleClickAction.actionId;
          }
        }

        try {
          switch (eventType) {
            case appActionClicked:
              if (eventPayload.actionId === manageBookingsEvent) {
                void openManageBookings(editorSdkAdapter, editorSDK, appToken);
              } else if (eventPayload.actionId === openBookingsAddPanel) {
                void handleOpenBookingsAddPanel(editorSDK, appToken);
              } else if (eventPayload.actionId === openBookingsPagesPanel) {
                void handleOpenBookingsPagesPanel(editorSDK, appToken);
              } else if (eventPayload.actionId === createAServiceEvent) {
                void openBookingsDashboardAddNewService(
                  editorSdkAdapter,
                  editorSDK,
                  appToken,
                );
              }
              break;
            case componentStyleChanged:
              void handleComponentStyleChange();
              break;
            case widgetGfppClicked:
              if (eventPayload.gfppOrigin === gfppOriginDoubleClick) {
                await handleDoubleClick();
              }
              if (eventPayload.id === manageBookingsEvent) {
                void openManageBookings(editorSdkAdapter, editorSDK, appToken);
              }
              if (eventPayload.id === manageStateEvent) {
                focusOnStateBox(eventPayload.componentRef);
              }
              if (eventPayload.id === openBookButtonSettingsEvent) {
                void openBookButtonSettingsPanel(eventPayload.componentRef);
              }
              if (eventPayload.id === openDailyTimeTableSettingsEvent) {
                void openDailyTimeTableSettingsPanel(eventPayload.componentRef);
              }
              if (eventPayload.id === openStaffListSettingsEvent) {
                void openStaffListSettingsPanel(eventPayload.componentRef);
              }
              if (eventPayload.id === manageStaffEvent) {
                void openManageStaff();
              }
              break;
            case componentGfppClicked:
              const parentWidget = await getControllerWidget(
                eventPayload.componentRef,
              );
              if (eventPayload.id === manageBookingsEvent) {
                void openManageBookings(editorSdkAdapter, editorSDK, appToken);
              }
              if (eventPayload.id === openStaffListSettingsEvent) {
                void openStaffListSettingsPanel(parentWidget);
              }
              if (eventPayload.id === manageStaffEvent) {
                void openManageStaff();
              }
              if (eventPayload.id === openDailyTimeTableSettingsTextTabEvent) {
                void openDailyTimeTableSettingsPanel(parentWidget, 'text');
              }
              if (eventPayload.id === openDailyTimeTableSettingsEvent) {
                void openDailyTimeTableSettingsPanel(parentWidget);
              }
              if (eventPayload.id === openStaffListTextSettingsEvent) {
                void openStaffListSettingsPanel(parentWidget, 'text');
              }
              if (eventPayload.id === manageStateEvent) {
                focusOnStateBox(parentWidget);
              }
              break;
            case manageBookingsEvent:
              void openManageBookings(editorSdkAdapter, editorSDK, appToken);
              break;
            case deleteBookingsEvent:
              const bookingsPages = await getAllBookingsPages(
                editorSDK,
                appToken,
              );
              const essentialPage = bookingsPages.find(
                (page) => page.tpaPageId === PageId.BOOKINGS_LIST,
              );
              await removePage(editorSDK, appToken, essentialPage.id);
              break;
            case deletePageEvent:
              await removePage(editorSDK, appToken, eventPayload.pageRef.id);
              break;
            case pageChangedEvent:
              const pageData = await getPageData(
                sdk,
                appToken,
                eventPayload.pageRef,
              );
              if (
                pageData.tpaPageId === PageId.SCHEDULER &&
                isProposeMigrationNeeded &&
                !(await isBookingsCheckoutInstalled(editorSDK))
              ) {
                await proposeListMigration(
                  sdk,
                  appToken,
                  locale,
                  instance,
                  editorTranslation,
                  allSitePages,
                );
              } else {
                if (
                  pageData.tpaPageId === PageId.BOOKINGS_LIST &&
                  !isAdi &&
                  (!(await isServicePageInstalled(sdk)) ||
                    !(await isBookingCalendarInstalled(sdk)) ||
                    !(await isBookingFormInstalled(sdk)))
                ) {
                  await migrator({
                    sdk,
                    appToken,
                    locale,
                    instance,
                    editorTranslation,
                    allSitePages,
                    editorScriptApi,
                    referral: pageData.tpaPageId,
                  });
                }
              }
              break;
            default:
          }
          fedopsLogger.interactionEnded(Actions.ON_EVENT);
        } catch (e) {
          const errorMessage = `${
            e?.message ? e.message : JSON.stringify(e)
          } - Action ${Actions.ON_EVENT}`;
          reportBi({
            msid,
            origin: editorOrigin,
            isFirstInstall,
            actionType: Actions.ON_EVENT,
            actionSucceed: false,
            errorMessage,
          });
          throw new Error(errorMessage);
        }
      },
      handleAction: async (
        { type, payload }: { type: string; payload: HandleActionPayload },
        editorSdk,
      ) => {
        try {
          fedopsLogger.interactionStarted(Actions.HANDLE_ACTION);

          const editorSdkAdapter = new EditorSdkAdapter(editorSdk, appToken);

          switch (type) {
            case 'appInstalled':
              fedopsLogger.interactionStarted(Actions.APP_INSTALLED);
              try {
                if (payload.appDefinitionId === ECOM_APP_DEF_ID) {
                  if (isBookingsOnEcom && isFirstInstall) {
                    isEcomReady = await markAsEcom({
                      isEcomReady,
                      editorSdk,
                      editorScriptApi,
                      appToken,
                    });
                  }
                }
                if (payload.appDefinitionId === BOOKINGS_APP_DEF_ID) {
                  fedopsLogger.interactionEnded(Actions.BOOKINGS_INSTALLATION);
                  if (
                    isBookingsOnEcom &&
                    isFirstInstall &&
                    (await editorSdkAdapter.isEcomInstalled())
                  ) {
                    isEcomReady = await markAsEcom({
                      isEcomReady,
                      editorSdk,
                      editorScriptApi,
                      appToken,
                    });
                  }
                }
                fedopsLogger.interactionEnded(Actions.APP_INSTALLED);
              } catch (e) {
                const errorMessage = `${
                  e?.message ? e.message : JSON.stringify(e)
                } - Action ${Actions.APP_INSTALLED}`;
                reportBi({
                  msid,
                  origin: editorOrigin,
                  isFirstInstall,
                  actionType: Actions.APP_INSTALLED,
                  actionSucceed: false,
                  errorMessage,
                });
                throw new Error(errorMessage);
              }
              break;
            case 'migrate':
              try {
                fedopsLogger.interactionStarted(Actions.MIGRATE);
                await migrateAction({
                  editorSdk,
                  appToken,
                  editorScriptApi,
                  editorOptions,
                  instance,
                  payload,
                  isBookingsOnEcom,
                });
                fedopsLogger.interactionEnded(Actions.MIGRATE);
              } catch (e) {
                const errorMessage = `${
                  e?.message ? e.message : JSON.stringify(e)
                } - Action ${Actions.MIGRATE}`;
                reportBi({
                  msid,
                  origin: editorOrigin,
                  isFirstInstall,
                  actionType: Actions.MIGRATE,
                  actionSucceed: false,
                  errorMessage,
                });
                throw new Error(errorMessage);
              }
              break;
            case 'removeApp':
              try {
                fedopsLogger.interactionStarted(Actions.APP_REMOVED);
                await onRemoveApp(sdk, appToken);
                fedopsLogger.interactionEnded(Actions.APP_REMOVED);
              } catch (e) {
                const errorMessage = `${
                  e?.message ? e.message : JSON.stringify(e)
                } - Action ${Actions.APP_REMOVED}`;
                reportBi({
                  msid,
                  origin: editorOrigin,
                  isFirstInstall,
                  actionType: Actions.APP_REMOVED,
                  actionSucceed: false,
                  errorMessage,
                });
                throw new Error(errorMessage);
              }
              break;
            default:
              return Promise.resolve();
          }
          fedopsLogger.interactionEnded(Actions.HANDLE_ACTION);
        } catch (e) {
          const errorMessage = `${
            e?.message ? e.message : JSON.stringify(e)
          } - Action ${Actions.HANDLE_ACTION}`;
          reportBi({
            msid,
            origin: editorOrigin,
            isFirstInstall,
            actionType: Actions.HANDLE_ACTION,
            actionSucceed: false,
            errorMessage,
          });
          throw new Error(errorMessage);
        }
      },
      getControllerPresets: async () => {
        return [];
      },
    };
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - Action: ${Actions.CREATE_EDITOR_SCRIPT}`;
    reportBi({
      actionType: Actions.CREATE_EDITOR_SCRIPT,
      actionSucceed: false,
      errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const createBookingsEditorScriptWithMembers = (
  isBookingsOnEcom = false,
) => {
  try {
    return withMembersArea(createEditorScript(isBookingsOnEcom), {
      installAutomatically: false,
      membersAreaApps: [MA_APP_IDS.MY_BOOKINGS, MA_APP_IDS.MY_WALLET],
    });
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: WITH_MEMBER_AREA_EDITOR_SCRIPT_ERROR`;
    throw new Error(errorMessage);
  }
};

export const createBookingsEditorScriptWithEcom = () => {
  try {
    return withEcomPlatform(
      createBookingsEditorScriptWithMembers(true) as any,
      () => requiredEcomComponents(),
      {
        skipInstallation: () => skipEcomInstallation(),
      },
    );
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: WITH_ECOM_PLATFORM_EDITOR_SCRIPT_ERROR`;
    throw new Error(errorMessage);
  }
};
